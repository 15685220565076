// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// TESTING

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: colors.green.darken4,			// #1B5E20
				secondary: colors.green.lighten2,		// #81C784
				accent: colors.green.accent3,				// #00E676
				featured: colors.green.lighten5,		// #E8F5E9
				navcolor: "#3f3d3e",
				navcoloractive: "#009c1f",

				// error:		'#FF5252'
				// warning:	'#FFC107'
				// info:		'#2196F3'
				// success:	'#4CAF50'
			},
			// default: {
				// primary: colors.green.darken4,			// #1B5E20
				// secondary: colors.green.lighten2,	// #81C784
				// accent: colors.green.accent3,			// #00E676
			// },
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi'
	},
	
	
});
