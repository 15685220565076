/* eslint-disable no-console */

import { register } from 'register-service-worker';
// import myStore from './store/index';



if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log('App is being served from cache by a service worker.')
      
			Notification.requestPermission( (status => { console.log("status:", status)}))
			
			// registration.periodicSync.register('get-latest-news', {
			// 	// Minimum interval at which the sync may fire.
			// 	minInterval: 3000, // 1 * 60 * 60 * 1000,
			// 	// minInterval: 24 * 60 * 60 * 1000,
			// });
			// registration.periodicSync.register('get-updates', {
			// 	minInterval: 10 * 1000
			// 	// minInterval: 2 * 60 * 60 * 1000
			// });
			
			// self.addEventListener('periodicsync', event => {
			// 	// event.waitUntil(myStore.dispatch('doSynchro'));
			// 	setTimeout(() => {
			// 		console.log('actualizando datos....');
			// 	}, 5 * 1000);
			// });

    },
		
    registered(registration) {
			console.log('Service worker has been registered.')
			setInterval(() => {
				registration.update();
			},
			1000 * 60 * 60); // Chequear si hay nueva versión cada 1 hora
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
			console.log('New content is available; please refresh.')
			document.dispatchEvent(
				new CustomEvent('swUpdated', { detail: registration })
			);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
	})
}