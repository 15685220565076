import dayjs from 'dayjs'
import 'dayjs/locale/es'
dayjs.locale('es')
//dayjs().format()

export default {
	methods: {
		fixDate(fecha) {
			if (!fecha || fecha == null || fecha == undefined) {
				fecha = dayjs()
			}
			return fecha
		},
		
		formatDateHuman(fecha) {
			return dayjs(this.fixDate(fecha), 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')
		},
		formatDateDiaMes(fecha) {
			return dayjs(this.fixDate(fecha), 'YYYY-MM-DD HH:mm:ss').format('DD.MMM')
		},
		formatDateMonthYear(fecha) {
			return dayjs(this.fixDate(fecha), 'YYYY-MM-DD HH:mm:ss').format('MMMM YYYY')
		},
		formatDateTime(fecha) {
			return dayjs(dayjs(fecha).format('YYYY-MM-DD HH:mm:ss'))
		},
		curDate(fecha) {			
			return dayjs(this.fixDate(fecha)).format('YYYY-MM-DD')
		},
		curDateTime(fecha) {
			return dayjs(this.fixDate(fecha)).format('YYYY-MM-DD HH:mm:ss')
		},
		
		leftFillNum(num, targetLength) {
    	return num.toString().padStart(targetLength, 0);
		},

		formatNumber(valor) {
			let formatedNumber = 0.00
			if (!isNaN(valor)) {
				formatedNumber = parseFloat(valor).toFixed(2)
			}
			return formatedNumber.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
		},

	},
	
}
