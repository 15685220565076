import { openDB, deleteDB } from 'idb';

const dbName = 'osapp';

const	dataFields =	[	"viewConfig" ]

const dbPromise = _ => {
	if (!window.indexedDB) {
		throw new Error('Este navegador no soporta IndexedDB. Recomendamos utilizar Google Chrome o FireFox.')
	}	
	const myDB = openDB(dbName, 10, {
		upgrade(db, oldVersion, newVersion, transaction) {
			dataFields.map(table => {
				if (!db.objectStoreNames.contains(table)) {
					db.createObjectStore(table)
				}
			})
		}
	})
	return myDB
}

const readStorage = async storeName => {
  return dbPromise()
    .then(db => {
      const tx = db.transaction(storeName, 'readonly')
      const store = tx.objectStore(storeName)
      return store.get(storeName)
    })
    .catch(error => {
			console.log('ERROR en lectura IDBS de', storeName, error)
      return error
    })
}

const saveToStorage = async (storeName, tasks) => {
	return dbPromise()
	.then(db => {
		const tx = db.transaction(storeName, 'readwrite')
		const store = tx.objectStore(storeName)
      store.put(tasks, storeName)
      return tx.complete
    })
    .catch(error => {
			console.log('ERROR en escritura IDBS' + tasks, error)
      return error
    })
}

const clearStorage = (storeName) => {
	localStorage.removeItem('token')
	return deleteDB(dbName).then(() => console.log('DB eliminada!'));
}
	
export default {
  readStorage,
	saveToStorage,
	clearStorage,
	dataFields
}
