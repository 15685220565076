import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import uuid from 'vue-uuid';

import "roboto-fontface/css/roboto/roboto-fontface.css"; // new
import "@mdi/font/css/materialdesignicons.css"; // new

import './registerServiceWorker';

Vue.use(VueAxios, axios, uuid)

// axios.defaults.baseURL = store.getters.isDeveloping ? 'https://autoc.osapp.com.ar/backend/api/' : '/backend/api/';
// axios.defaults.baseURL = store.getters.isDeveloping ? 'http://localhost/api/' : '/backend/api/';
axios.defaults.baseURL = store.getters.isDeveloping ? 'http://localhost/osapp/api/' : '/backend/api/';
// axios.defaults.baseURL = 'http://localhost/osapp/api/';

// if (store.getters.isDeveloping) { console.log('url:', axios.defaults.baseURL)}

Vue.config.productionTip = false

new Vue({
	router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


